import { ModularComponent } from '@/utils/modularComponent';
import { ModularResponsiveOption } from '@/utils/modularComponent/modularResponsiveStyle';

import type { BookComponents, ModularBookComponents } from '../BookDefinitionContext';
import { LandscapeBookPreset } from './landscape';
import { PortraitBookPreset } from './portrait';

export type BookPresetComponents = {
  [K in keyof ModularBookComponents]: ModularBookComponents[K] extends ModularComponent<infer O, infer P>
    ? ModularComponent<Partial<O>, P>
    : never;
} & {
  readonly [K in keyof BookComponents]: BookComponents[K];
};

export type BookPreset = (components: BookPresetComponents) => BookPresetComponents;

type BookAvailableSize = 50 | 60 | 80 | 90 | 100 | 110 | 120 | 140 | 150 | 200;
export const BookSizePreset =
  (size: BookAvailableSize | ModularResponsiveOption<BookAvailableSize>): BookPreset =>
  components => {
    const responsiveSize = typeof size === 'number' ? [{ value: size }] : size;
    const { BookThumbnail, BookRenderer } = components;

    const presetComponents = {
      ...components,
      BookThumbnail: BookThumbnail.withOptions({ size: responsiveSize }),
      BookRenderer: BookRenderer.withOptions({ size: responsiveSize }),
    };

    responsiveSize.forEach(sizeOption => {
      if (sizeOption.value <= 60) {
        const {
          BookAdultIndicator,
          BookBadge,
          BookDiscountBadge,
          BookNewBadge,
          BookRentalBadge,
          BookSetbook,
          BookTitle,
        } = presetComponents;

        // prettier-ignore
        presetComponents.BookAdultIndicator = BookAdultIndicator
          .addResponsiveOption('size', {...sizeOption, value: 15 })
          .addResponsiveOption('offset', { ...sizeOption, value: 2 });

        // prettier-ignore
        presetComponents.BookBadge = BookBadge
          .addResponsiveOption('size', { ...sizeOption, value: 32 });

        // prettier-ignore
        presetComponents.BookDiscountBadge = BookDiscountBadge
          .addResponsiveOption('numberSize', { ...sizeOption, value: 12 })
          .addResponsiveOption('percentSize', { ...sizeOption, value: 10 });

        // prettier-ignore
        presetComponents.BookNewBadge = BookNewBadge
          .addResponsiveOption('textSize', { ...sizeOption, value: 10 });

        // prettier-ignore
        presetComponents.BookRentalBadge = BookRentalBadge
          .addResponsiveOption('textSize', { ...sizeOption, value: 12 });

        // prettier-ignore
        presetComponents.BookSetbook = BookSetbook
          .addResponsiveOption('textSize', { ...sizeOption, value: 11 });

        // prettier-ignore
        presetComponents.BookTitle = BookTitle
          .addResponsiveOption('textSize', { ...sizeOption, value: 12 })
          .addResponsiveOption('light', { ...sizeOption, value: true });
      }

      if (sizeOption.value >= 150) {
        const { BookSetbook } = presetComponents;

        // prettier-ignore
        presetComponents.BookSetbook = BookSetbook
          .addResponsiveOption('textSize', { ...sizeOption, value: 13 });
      }

      if (sizeOption.value >= 160) {
        const { BookSetbook } = presetComponents;

        // prettier-ignore
        presetComponents.BookSetbook = BookSetbook
          .addResponsiveOption('textSize', { ...sizeOption, value: 16 })
          .addResponsiveOption('borderSize', { ...sizeOption, value: 3 })
          .addResponsiveOption('padding', { ...sizeOption, value: '10px' });
      }
    });

    return presetComponents;
  };

export const BookDefaultPreset =
  (
    orientation: 'landscape' | 'portrait',
    size: BookAvailableSize | ModularResponsiveOption<BookAvailableSize>,
  ): BookPreset =>
  components => {
    const OrientationPreset = orientation === 'landscape' ? LandscapeBookPreset : PortraitBookPreset;
    const SizePreset = BookSizePreset(size);

    // OrientationPreset이 나중에 적용되어야 함
    // (BookTitle은 가로에서 언제나 14px)
    return OrientationPreset(SizePreset(components));
  };

export * from './landscape';
export * from './portrait';
export * from './renewal';
