import { ReactNode, useContext } from 'react';

import { IconDiscount } from '@/assets/svgs';
import { a11y } from '@/components/styles/reset';
import { formatNumber } from '@/utils/formatNumber';
import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import { BookDataContext, BookRenderData } from '../BookDataContext';
import { BookDefinitionContext } from '../BookDefinitionContext';
import * as styles from './BookPrice.styles';

type ExtractDeepPartial<T> = T extends DeepPartial<infer U> ? U : never;
type BookPriceRenderData = ExtractDeepPartial<BookRenderData>['metadata']['extra']['price'];

interface BookPriceDiscountItemProps {
  percentage: number;
  className?: string;
  children?: ReactNode;
}

interface BookOriginalPriceInfoItemProps {
  className?: string;
  originalPrice: number;
}

export const BookPriceDiscountItem = modularComponent(
  () =>
    ({ className, percentage, children }: BookPriceDiscountItemProps) => (
      <span css={styles.discountInfoStyle} className={className}>
        ({`${Math.ceil(percentage)}%`}
        <IconDiscount aria-label="할인" preserveAspectRatio="xMidYMid slice" css={styles.discountIconStyle} />)
        {children}
      </span>
    ),
);

export const BookPriceInfoItem = modularComponent(() => ({ className, children }) => (
  <span css={styles.priceInfoStyle} className={className}>
    {children}
  </span>
));

export const BookPriceAdditionalInfoItem = modularComponent(() => ({ className, children }) => (
  <span css={styles.additionalInfoStyle} className={className}>
    {children}
  </span>
));

export const BookOriginalPriceInfoItem = modularComponent(
  () =>
    ({ className, originalPrice }: BookOriginalPriceInfoItemProps) => (
      <del css={styles.originalPriceStyle} className={className}>
        {`${formatNumber(originalPrice)}원`}
      </del>
    ),
);

interface BookPriceItemProps {
  label: string;
  priceData?: DeepPartial<BookPriceRenderData['purchase'] | BookPriceRenderData['rental']>;
  totalPriceData?: DeepPartial<BookPriceRenderData['setbookTotalPurchase'] | BookPriceRenderData['setbookTotalRental']>;
  className?: string;
  children?: ReactNode;
}

export const BookPriceItem = modularComponent(
  ({ showAdditionalInfo, showDiscountInfo, textSize }) => {
    const priceItemWrapperStyle = [
      styles.priceItemWrapperStyle,
      modularResponsiveStyle(styles.priceItemWrapperSizeStyle, textSize),
    ];

    return ({ className, children, label, priceData, totalPriceData }: BookPriceItemProps) => {
      const {
        BookPriceDiscountItem: BookContextPriceDiscountItem,
        BookPriceInfoItem: BookContextPriceInfoItem,
        BookPriceAdditionalInfoItem: BookContextPriceAdditionalInfoItem,
        BookOriginalPriceInfoItem: BookContextOriginalPriceInfoItem,
      } = useContext(BookDefinitionContext).components;
      if (
        !priceData ||
        typeof priceData?.minSellingPrice !== 'number' ||
        !Number.isFinite(priceData?.minSellingPrice) ||
        priceData?.minSellingPrice < 0
      ) {
        return <></>;
      }

      const baseInfo = (
        <BookContextPriceInfoItem>
          {priceData.minSellingPrice === 0 ? '무료' : `${formatNumber(priceData.minSellingPrice)}원`}
        </BookContextPriceInfoItem>
      );

      let extra: ReactNode = <></>;
      if (!totalPriceData) {
        // 세트도서가 아닌 경우에는 단권에 대한 할인 정보를 표시한다
        const discountPercentage = priceData.discountPercentage ?? 0;
        const hasDiscountInfo = !!discountPercentage && discountPercentage >= 10 && discountPercentage <= 100;
        const originalUnitPrice = priceData.originalPrice !== undefined && (
          <BookContextOriginalPriceInfoItem originalPrice={priceData.originalPrice} />
        );

        extra = showDiscountInfo && hasDiscountInfo && (
          <BookContextPriceDiscountItem percentage={discountPercentage}>
            {originalUnitPrice}
          </BookContextPriceDiscountItem>
        );
      } else if (totalPriceData.discountPrice !== 0 && showAdditionalInfo) {
        // 세트도서인 경우에는 전권 할인 정보를 표시한다
        const originalPriceInfo = totalPriceData.originalPrice !== undefined && (
          <BookContextOriginalPriceInfoItem originalPrice={totalPriceData.originalPrice} />
        );

        const discountPercentage = totalPriceData.discountPercentage ?? 0;
        const hasDiscountInfo = !!discountPercentage && discountPercentage > 0 && discountPercentage <= 100;
        const discountInfo = showDiscountInfo && hasDiscountInfo && (
          <BookContextPriceDiscountItem percentage={discountPercentage}>
            {originalPriceInfo}
          </BookContextPriceDiscountItem>
        );

        extra = (
          <BookContextPriceAdditionalInfoItem>
            {`전권 ${label} `}
            {totalPriceData.discountPrice && (
              <BookContextPriceInfoItem>{formatNumber(totalPriceData.discountPrice)}원</BookContextPriceInfoItem>
            )}
            {/* {!totalPriceData.discountPrice && <BookContextPriceInfoItem>무료</BookContextPriceInfoItem>} */}
            {discountInfo}
          </BookContextPriceAdditionalInfoItem>
        );
      } else if (totalPriceData.discountPrice === 0 && showAdditionalInfo) {
        extra = (
          <BookContextPriceAdditionalInfoItem>
            {`전권 ${label} `}
            <BookContextPriceInfoItem>무료</BookContextPriceInfoItem>
          </BookContextPriceAdditionalInfoItem>
        );
      }

      return (
        <p css={priceItemWrapperStyle} className={className}>
          {`${label} `}
          {baseInfo}
          {extra}
          {children}
        </p>
      );
    };
  },
  { showAdditionalInfo: false, showDiscountInfo: false, textSize: [{ value: 12 }] as ModularResponsiveOption<number> },
);

export const BookPrice = modularComponent(() => ({ className }) => {
  const bookData = useContext(BookDataContext);
  const { BookPriceItem: PriceItem } = useContext(BookDefinitionContext).components;

  const priceData = bookData.metadata?.extra?.price;
  const hasNoPriceData =
    !priceData ||
    (!priceData.rental && !priceData.setbookTotalRental && !priceData.purchase && !priceData.setbookTotalPurchase);

  if (hasNoPriceData) {
    return <></>;
  }

  return (
    <div css={styles.priceWrapperStyle} className={className}>
      <span css={a11y}>상세 가격</span>
      <PriceItem label="대여" priceData={priceData?.rental} totalPriceData={priceData?.setbookTotalRental} />
      <PriceItem label="소장" priceData={priceData?.purchase} totalPriceData={priceData?.setbookTotalPurchase} />
    </div>
  );
});
