import type { BookPreset } from '.';

export const PortraitBookPreset: BookPreset = components => {
  const { BookAuthors, BookDefaultMetadataItem, BookStarRateFull, BookPriceItem } = components;

  return {
    ...components,
    BookAuthors: BookAuthors.withOptions({ excludeTranslators: false }),
    BookDefaultMetadataItem: BookDefaultMetadataItem.withOptions({ textSize: [{ value: 12 }] }),
    BookStarRate: BookStarRateFull.withOptions({ size: [{ value: 11 }] }),
    BookPriceItem: BookPriceItem.withOptions({ showAdditionalInfo: false, textSize: [{ value: 12 }] }),
  };
};
