import { css } from '@emotion/react';

import type { BookPreset } from '..';
import { LandscapeBookMetadataItems, LandscapeBookMetadataRenderer } from '../LandscapeBookMetadataRenderer';
import { LandscapeBookRenderer } from '../LandscapeBookRenderer';

const titleBadgeBreakStyle = css`
  display: inline-flex;
`;

export const LandscapeBookPreset: BookPreset = components => {
  const {
    BookDefaultMetadataItem,
    BookStarRateMinimal,
    BookTitle,
    BookTitleBadgeRenderer,
    BookAuthors,
    BookPriceItem,
  } = components;

  return {
    ...components,
    BookMetadataRenderer: LandscapeBookMetadataRenderer,
    BookMetadataItems: LandscapeBookMetadataItems,
    BookRenderer: LandscapeBookRenderer,
    BookDefaultMetadataItem: BookDefaultMetadataItem.withOptions({ textSize: [{ value: 13 }] }),
    BookStarRate: BookStarRateMinimal.withOptions({ size: [{ value: 13 }] }),
    BookPriceItem: BookPriceItem.withOptions({ showAdditionalInfo: true, textSize: [{ value: 13 }] }),
    BookTitle: BookTitle.withOptions({ textSize: [{ value: 14 }], hoverEffect: true }),
    BookTitleBadgeRenderer: BookTitleBadgeRenderer.addStyle(titleBadgeBreakStyle),
    BookAuthors: BookAuthors.withOptions({ textSize: [{ value: 14 }], clipCount: 2 }),
  };
};
