import { memo, ReactNode, useContext, useMemo } from 'react';

import { BookDataContext, BookRenderData, BookTrackingOptions } from './BookDataContext';
import { BookComponents, BookDefinitionContext } from './BookDefinitionContext';

interface BookProps {
  data: BookRenderData;
  children: (components: BookComponents) => ReactNode;
  trackingOptions?: BookTrackingOptions['trackingOptions'];
}

/**
 * @summary &lt;BookSection&gt; 내부에서 책을 그리기 위해 사용하는 컴포넌트
 * @description
 * BookSection에서 책을 그릴 형태를 정의하면, 그 하단에서 Book으로 책을 여러개 그릴 수 있음
 * @see {@link BookSection} 에서 책 내부의 컴포넌트 변경 가능
 * @see {@link BookDataContext} 에서 받는 데이터 확인 가능
 * @param {BookRenderData} data 그릴 책의 정보
 * @example
 * const MyBook = ({ data }: { data: MyAPIBook }) => (
 *   <Book data={mapAPIResponseToRenderData(data)}>
 *     ({ BookRenderer }) => <BookRenderer />
 *   </Book>
 * );
 */
export const Book = memo(({ data, children, trackingOptions }: BookProps): ReactJSX.Element => {
  const { components } = useContext(BookDefinitionContext);

  const bookData = useMemo(() => ({ ...data, trackingOptions }), [data, trackingOptions]);
  const childrenNodes = children(components);

  return <BookDataContext.Provider value={bookData}>{childrenNodes}</BookDataContext.Provider>;
});
